@media only screen and (min-width: 768px) {
.logo_1 {
    margin: 5px ;
    width : 15.75rem !important; 
}
.logo_2 {
    margin: 5px ;
   
}
.logo_3 {
    margin: 5px ;
    width : 15.75rem !important; 
}
.logo_4 {
    margin: 5px ;
    width : 21.75rem !important; 
}

}